
<script setup>
    import { reactive, ref, useSlots, computed, defineEmits, watch, onMounted, onBeforeUnmount } from "vue";
    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";
    import MobileProps from "o365.controls.MobileLookup.Props.ts";
    import MBottomSheet from "mobile.vue.components.BottomSheet.vue";
    import MListView from "mobile.vue.components.ListView.vue";
    import MListItem from "mobile.vue.components.ListItem.vue";
    import { parseColumnsFromVNodes } from "o365.vue.components.DataGrid.Column.jsx";
    import OToolbarAction from "o365.vue.components.ToolbarAction.vue";
    import DataLookupControl from "o365.modules.DataLookup.ts";
    import { combineWhereClauses } from "mobile.utils.ts";

    defineOptions({
        inheritAttrs: false,
    });

    const props = defineProps({ ...MobileProps, title: String });
    const emit = defineEmits(["beforeopen", "open", "beforeclose", "close", "update:modelValue"]);

    // I don't see a reason to not force reload, it fixes many potential issues
    //props.forceReloadOnOpen = true;

    const lookupControl = reactive(new DataLookupControl(props));

    const show = ref(false);
    const search = ref("");

    const teleportTarget = ref("body");

    const slots = useSlots();
    const target = ref(null);

    const model = computed({
        get: () => props.modelValue ? props.modelValue : props.value,
        set: (val) => emit("update:modelValue", val),
    });

    const vnodes = slots["default"]();
    const columns = parseColumnsFromVNodes(vnodes);

    /* functions */

    function load() {        
        props.dataObject.load();
        //lookupControl.load(true);
    }

    function getWhereClause() {
        return props.whereClause ?? "";
    }

    function getFilterString() {
        if (!search.value) {
            return "";
        }
        const clauses = [];
        const searchTerms = search.value.split(" ");
        for (let col of columns) {
            const name = col.field || col.name;
            if (props.dataObject.getFields(name)) {
                const subClauses = searchTerms.map(s => `[${name}] LIKE '%${s}%'`);
                clauses.push("(" + subClauses.join(" AND ") + ")");
            }
        }
        return combineWhereClauses(clauses, "OR");
    }

    function updateClauses() {
        if (props.whereClause) {
            props.dataObject.recordSource.whereClause = getWhereClause();
        }
        props.dataObject.recordSource.filterString = getFilterString();
    }


    /* context filtering */

    const contextField = computed(() => lookupControl.contextField);
    const contextFilterEnabled = ref(!!lookupControl.contextFilterEnabled);

    watch(contextFilterEnabled, () => {
        if (!contextField.value) {
            return;
        }
        if (contextFilterEnabled.value) {
            lookupControl.enableContextFilter();
        } else {
            lookupControl.disableContextFilter();
        }
        lookupControl.dataObject.load();
    });

    // watch([contextField, contextFilterEnabled], () => {
        // console.log("contextField", contextField.value, "contextFilterEnabled", contextFilterEnabled.value, "dataobject", props.dataObject?.id);
    // }, { immediate: true });

    /* watch */

    watch(show, (val) => {
        if (val) {
            updateClauses();
            load();
            emit("open");
        } else {
            emit("close");
        }
    });

    watch(() => props.bind, (newValue) => {
        lookupControl._bind = newValue;
    });

    
    watch(() => props.whereClause, (newValue) => {
        lookupControl.whereClause = newValue;
    });

    watch(() => props.whereClause, (newValue) => {
        updateClauses();
        load();
    });

    watch(search, () => {        
        updateClauses();
        load();
    });


    /* handlers */

    function onClick(e) {
        show.value = !show.value;
        //document.activeElement?.blur();
        //e.preventDefault();
    }

    function onSearch(val) {
        search.value = val;
    }

    function onSelect(row) {
        if (props.multiselect) {
            lookupControl.handleMultiselect();
        } else {
            lookupControl.handleRowClick(row);
        }
        show.value = false;
    }

    function onClear() {
        lookupControl.handleClearSelection();
        show.value = false;
    }


    /* lifecycle */
    
    onMounted(() => {
        target.value.addEventListener("click", onClick);
        
        if (target.value) {
            const modal = target.value.closest(".modal");
            if (modal) {
                teleportTarget.value = modal;
            }
        }
    });

    onBeforeUnmount(() => {
        target.value.removeEventListener("click", onClick);
    });

    defineExpose({ lookupControl, activateEditor: () => console.log("activateEditor NYI"), open: () => { show.value = true; } });

    //const editables = computed(() => columns.map(e => e.editable).filter(e => typeof e === "function"));


</script>


<template>
    <!-- label -->
    <label v-if="label">{{ label }}</label>

    <!-- target -->
    <slot name="target" :target="el => target = el" :open="() => show = true" >
        <input ref="target" v-model="model" v-bind="$attrs" readonly class="lookup-icon" type="text">
    </slot>

    <!-- sheet -->
    <MBottomSheet v-model="show" min-height="calc(100% - 4rem)" max-height="calc(100% - 4rem)" :teleportTarget="teleportTarget">
        <template #title>
            {{ props.title || columns[0].field || columns[0].name }}
        </template>
        <template #action>
            <a
                v-if="!noClear"
                class="ms-2 p-2 d-block text-primary"
                style="font-size: 1em; text-decoration: none;"
                @click="onClear()"
            >
                {{ $t("Clear") }}
            </a>
        </template>
        <template #body>
            <MContainer>
                <MContent>
                    <MListView
                        :data-object="dataObject"
                        :multiselect="multiselect"
                        @select="onSelect"
                        @search="onSearch"
                        :noSearch="noSearch"
                    >
                        <template #pills v-if="$slots.actions || $slots.toolbarActions || contextField">
                            <template v-if="contextField">
                                <OToolbarAction :text="$t('Restrict to context')" v-model="contextFilterEnabled" />
                            </template>
                            <slot name="actions" />
                            <slot name="toolbarActions" />
                        </template>
                        <template v-for="row in dataObject.data">
                            <slot name="mobileItem" :row="row">
                                <MListItem :row="row">
                                    <template v-for="(col, colIndex) in columns">
                                        <div :class="{ 'fw-medium': columns.length >= 2 && colIndex == 0 }">
                                            <!-- there needs to be a good reason to show this much clutter on mobile -->
                                            <!--
                                            {{ col.field || col.name }}: {{ row[col.field || col.name] }}
                                            -->
                                            {{ row[col.field || col.name] }}
                                        </div>
                                    </template>
                                </MListItem>
                            </slot>
                        </template>
                    </MListView>
                </MContent>
            </MContainer>
        </template>
    </MBottomSheet>
</template>

<style scoped>
    input {
        padding-right: 2rem;
    }

</style>
